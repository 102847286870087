import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0DB678",
      contrastText: "#0B0F15",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#0B0F15",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCardMedia: {
      styleOverrides: {
        root: {
          width: "140px",
          height: "140px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255,0.2)",
        },
      },
    },
  },
});

const Theme = responsiveFontSizes(theme);

export default Theme;
