import React from "react";
import { ThemeProvider } from "@mui/material";
import AppRouter from "./components/AppRouter";
import Theme from "./components/theme/Theme";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <RecoilRoot>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
          <AppRouter />
        </RecoilRoot>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
