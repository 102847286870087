import React from "react";

import { APIRequestStatus } from "./rest-client/types";
import { CircularProgress, Grid, Typography } from "@mui/material";

interface APIDataLoaderProps extends APIRequestStatus {
  loadingIndicator?: React.ReactElement;
}

export const LoadingIndicator = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

const APIDataLoader = (props: React.PropsWithChildren<APIDataLoaderProps>) => {
  const {
    children,
    hasError,
    isLoading,
    success,
    loadingIndicator = <LoadingIndicator />,
  } = props;

  if (!success && isLoading) {
    return loadingIndicator;
  }

  if (hasError) {
    return (
      <Typography color="primary" align="center">
        <br />
        Data not found!
        <br />
      </Typography>
    );
  }

  return <>{children}</>;
};

export default APIDataLoader;
