export interface APIRequestStatus {
  isLoading: boolean;
  hasError: boolean;
  success: boolean;
}

export interface APIData<Data, ErrorType = any> extends APIRequestStatus {
  error: ErrorType;
  data: Data;
  request?: any;
}

const DefaultRequestValues: APIData<any> = {
  data: null,
  isLoading: false,
  success: false,
  hasError: false,
  error: null,
};

export default DefaultRequestValues;
