import React, { useEffect } from "react";
import usePublicDashboards from "./DashboardsClient";
import APIDataLoader from "../common/APIDataLoader";
import { Box, Container, Typography } from "@mui/material";
import DashboardCard from "./DashboardCard";

const Dashboards = () => {
  const { dashboards, getDashboards } = usePublicDashboards();

  useEffect(() => {
    getDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ py: 5 }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "30px",
          color: "#ffffff",
          textAlign: "center",
        }}
      >
        Dashboards built using Unmarshal Parser
      </Typography>
      <Box
        sx={{
          mt: { md: 3, lg: 5 },
          width: "100%",
          typography: "body1",
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fill, 120px)",
            md: "repeat(auto-fill, 250px)",
            "& :hover": {
              transform: "scale(1.1)",
            },
          },
          gap: { xs: "16px", md: "30px" },
          flexWrap: "wrap",
          flexGrow: 0,
          justifyContent: "center",
        }}
      >
        <APIDataLoader {...dashboards}>
          {dashboards?.data?.map((dashboard, index) => {
            return <DashboardCard key={dashboard.uuid} dashboard={dashboard} />;
          })}
        </APIDataLoader>
      </Box>
    </Container>
  );
};

export default Dashboards;
