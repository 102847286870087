import { SetterOrUpdater } from "recoil";
import useRestClient from "./RestClient";
import DefaultRequestValues, { APIData } from "./types";

const useGetRequest = <DataType>() => {
  const { get } = useRestClient();
  return (
    setterOrUpdater: SetterOrUpdater<APIData<DataType>>,
    path: string,
    options: any = {}
  ) =>
    get(path, {
      ...options,
      beforeFetch: () =>
        setterOrUpdater((currentValue) => ({
          ...currentValue,
          isLoading: true,
        })),
      onSuccess: ({ data }) =>
        setterOrUpdater({
          ...DefaultRequestValues,
          data,
          success: true,
        }),
      onError: (error) =>
        setterOrUpdater({
          ...DefaultRequestValues,
          hasError: true,
          success: false,
          error,
        }),
    });
};

export default useGetRequest;
