import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { toDashboard, toLandingPage } from "../routes/routes";
import usePublicDashboards from "./DashboardsClient";
import APIDataLoader from "../common/APIDataLoader";

const DashboardDisplay = () => {
  const { id } = useParams();
  const { dashboards, getDashboards } = usePublicDashboards();

  const index = dashboards?.data?.findIndex(
    (dashboard) => dashboard?.uuid === id
  );

  useEffect(() => {
    getDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <APIDataLoader {...dashboards}>
      <Stack direction={"row"} justifyContent={"center"}>
        <Stack
          direction={"column"}
          justifyContent={"flex-start"}
          width={"15vw"}
          sx={{ maxHeight: "90vh", overflowY: "auto" }}
        >
          {dashboards?.data?.map((dashboard, idx) => {
            return (
              <Link
                to={toDashboard(dashboard.uuid)}
                style={{
                  textDecoration: "none",
                  color: "white",
                  borderBottom: "1px solid rgba(255,255,255,0.2)",
                }}
              >
                <Tooltip title={dashboard.name} placement={"top"} arrow>
                  <Typography
                    noWrap
                    sx={{
                      padding: "8px 12px",
                      backgroundColor:
                        idx === index ? "rgba(255,255,255,0.15)" : "#0B0F15",
                      color:
                        idx === index ? "#0DB678" : "rgba(255,255,255,0.6)",
                      cursor: "pointer",
                      "& :hover": {
                        backgroundColor: "rgba(255,255,255,0.5)",
                      },
                    }}
                  >
                    {dashboard.name}
                  </Typography>
                </Tooltip>
              </Link>
            );
          })}
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          width={"85vw"}
          direction={"column"}
          justifyContent={"center"}
          sx={{ p: "8px 24px" }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to={toLandingPage()}
              style={{
                textDecoration: "none",
                color: "#0DB678",
                cursor: "pointer",
              }}
            >
              <Typography sx={{ p: 1 }}>Dashboards</Typography>
            </Link>
            <Typography sx={{ p: 1 }}>
              {dashboards?.data && dashboards?.data[index]?.name}
            </Typography>
          </Breadcrumbs>
          <iframe
            title={dashboards?.data && dashboards?.data[index]?.name}
            src={dashboards?.data && dashboards?.data[index]?.dashboard_url}
            frameBorder="0"
            style={{
              width: "100%",
              height: "85vh",
            }}
            allowTransparency
          />
        </Stack>
      </Stack>
    </APIDataLoader>
  );
};

export default DashboardDisplay;
