import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardDisplay from "./dashboards/DashboardDisplay";
import Appbar from "./Appbar";
import NewDashboard from "./dashboards/NewDashboard";
import Dashboards from "./dashboards/Dashboards";

const AppRouter = () => {
  return (
    <>
      <Appbar />
      <Routes>
        <Route path="/" element={<Dashboards />} />
        <Route path={"/dashboard/:id"} element={<DashboardDisplay />} />
        <Route path={"/new"} element={<NewDashboard />} />
      </Routes>
    </>
  );
};

export default AppRouter;
