import { Card, CardContent, Container } from "@mui/material";
import NewDashboardForm from "./NewDashboardForm";
import { toLandingPage } from "../routes/routes";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";

const NewDashboard = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth={"md"} sx={{ mt: 10 }}>
      <Card>
        <CardContent>
          <IconButton
            sx={{ float: "right" }}
            onClick={() => {
              navigate(toLandingPage());
            }}
          >
            <Close />
          </IconButton>
          <NewDashboardForm />
        </CardContent>
      </Card>
    </Container>
  );
};

export default NewDashboard;
