import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultRequestValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import usePostRequest from "../common/rest-client/post";

export interface Dashboard {
  uuid: string;
  name: string;
  description: string;
  email: string;
  dashboard_url: string;
  display_order: number;
  logo_url: string;
  status: string;
}

const DashboardsAtom = atom<APIData<Array<Dashboard>>>({
  key: "Public Dashboards",
  default: DefaultRequestValues,
});

const DashboardAtom = atom<APIData<Dashboard>>({
  key: "New Dashboard",
  default: DefaultRequestValues,
});
const usePublicDashboards = () => {
  const [dashboards, updateDashboards] = useRecoilState(DashboardsAtom);
  const resetDashboards = useResetRecoilState(DashboardsAtom);
  const get = useGetRequest<Array<Dashboard>>();
  const getDashboards = () => {
    get(updateDashboards, `/api/dashboards?timestamp=${new Date().getTime()}`);
  };

  const [newDashboard, updateNewDashboard] = useRecoilState(DashboardAtom);
  const resetNewDashboard = useResetRecoilState(DashboardAtom);
  const post = usePostRequest<Dashboard>();
  const createDashboard = (data: any) => {
    post(updateNewDashboard, "/api/dashboards", {
      data,
    });
  };

  return {
    dashboards,
    resetDashboards,
    getDashboards,
    newDashboard,
    createDashboard,
    resetNewDashboard,
  };
};

export default usePublicDashboards;
