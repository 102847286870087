import { Dashboard } from "./DashboardsClient";
import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { wrapMiddle } from "../common/string/format";
import { useNavigate } from "react-router-dom";
import { toDashboard } from "../routes/routes";

const DashboardCard = ({ dashboard }: { dashboard: Dashboard }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      key={dashboard.uuid}
      sx={{
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        background: `url(${dashboard.logo_url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "& :hover": {
          transform: "scale(1)",
          cursor: "pointer",
        },
      }}
      onClick={() => {
        navigate(toDashboard(dashboard.uuid));
      }}
    >
      <div
        style={{
          textDecoration: "none",
          color: "white",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          backdropFilter: "blur(50px)",
          position: "static",
          paddingTop: "10px",
        }}
      >
        <CardMedia
          component="img"
          alt="logo"
          image={dashboard.logo_url}
          sx={{
            width: { xs: 70, md: 140 },
            height: { xs: 70, md: 140 },
            borderRadius: "8px",
          }}
        />
        <CardContent>
          <Tooltip title={dashboard.name} placement={"top"}>
            <Typography variant="h6" component="div" align="center">
              {media
                ? wrapMiddle(dashboard.name, 9, 0)
                : wrapMiddle(dashboard.name, 14, 0)}
            </Typography>
          </Tooltip>
          <Tooltip title={dashboard.description} placement={"top"}>
            <Typography variant="body2" align={"center"}>
              {media
                ? wrapMiddle(dashboard.description, 10, 0)
                : wrapMiddle(dashboard.description, 25, 0)}
            </Typography>
          </Tooltip>
        </CardContent>
      </div>
    </Card>
  );
};

export default DashboardCard;
