import React from "react";
import {
  Button,
  Container,
  Divider,
  Hidden,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toLandingPage, toNewDashboard } from "./routes/routes";
import logo from "../components/assets/logo.svg";

const Appbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <Paper sx={{ background: "none", py: 2, minHeight: 30, borderRadius: 0 }}>
        <Container maxWidth={"lg"} sx={{ padding: { sm: 2, lg: 0 } }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Link to={toLandingPage()}>
                <img src={logo} height={50} alt="unmarshal logo" />
              </Link>
              <Hidden mdDown>
                <Typography
                  variant={"h6"}
                  display={"inline"}
                  fontWeight={"bold"}
                >
                  &nbsp; Unmarshal
                </Typography>
              </Hidden>
            </Stack>
            <Button
              variant={"outlined"}
              color={"secondary"}
              sx={{
                height: "44px",
                "&:hover": { color: "#0DB678", backgroundColor: "#ffffff" },
              }}
              onClick={() => navigate(toNewDashboard())}
            >
              Add Dashboard
            </Button>
          </Stack>
        </Container>
      </Paper>
      <Divider />
    </>
  );
};

export default Appbar;
