import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import usePublicDashboards from "./DashboardsClient";
import { Button, Stack, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toLandingPage } from "../routes/routes";

const NewDashboardForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { newDashboard, createDashboard, resetNewDashboard } =
    usePublicDashboards();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (newDashboard.hasError) {
      toast.error(newDashboard.error.response.data.error);
      setSubmitting(false);
    }
    if (newDashboard.success) {
      toast.success(
        <>
          <Typography variant={"subtitle1"} fontWeight={500}>
            Thank you for submitting your dashboard.
          </Typography>
          <Typography variant={"body1"} color={"#808191"} fontSize={"12px"}>
            Our team is processing your request
          </Typography>
          <Typography variant={"body1"} color={"#808191"} fontSize={"12px"}>
            It will be added once approved.
          </Typography>
        </>
      );
      setSubmitting(false);
      navigate(toLandingPage());
    }
    return resetNewDashboard;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDashboard]);

  function handleFileInput(e: any) {
    if (
      e.target.files[0].size / 1024 >= 1 &&
      e.target.files[0].size / 1024 / 1024 < 1
    ) {
      setFile(e?.target?.files[0]);
      setFileName(e?.target?.files[0].name);
    } else {
      toast.warn("File size should be between 1KB and 1MB");
    }
  }

  const onSubmit = ({ name, email, description, dashboard_url }: any) => {
    setSubmitting(true);
    const parsedURL = new URL(dashboard_url);
    if (parsedURL.protocol !== "https:") {
      toast.warn("Invalid protocol(Use https)");
      setSubmitting(false);
      return;
    }
    if (
      parsedURL.host !== "analysis.prod.unmarshal.com" &&
      parsedURL.host !== "analytics.unmarshal.com"
    ) {
      toast.warn("Invalid dashboard URL host");
      setSubmitting(false);
      return;
    }
    if (fileName === "") {
      toast.warn("Logo required");
      setSubmitting(false);
      return;
    }
    const fd = new FormData();
    fd.append("name", name);
    fd.append("email", email);
    fd.append("description", description);
    fd.append("dashboard_url", dashboard_url);
    fd.append("display_order", "14");
    fd.append("logo", file, fileName);
    createDashboard(fd);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        sx={{
          mb: 2,
          width: { md: "100%", lg: "50%" },
          background: "rgba(255, 255, 255, 0.06)",
        }}
        required
        type={"text"}
        label={"Enter dashboard name"}
        autoComplete={"off"}
        {...register("name", { required: true })}
        error={!!errors?.name}
        helperText={errors?.name?.messages}
      />
      <TextField
        sx={{
          mb: 2,
          width: { md: "100%", lg: "50%" },
          background: "rgba(255, 255, 255, 0.06)",
        }}
        type={"email"}
        label={"Enter email address"}
        autoComplete={"off"}
        {...register("email")}
        error={!!errors?.email}
        helperText={errors?.email?.messages}
      />
      <TextField
        sx={{ mb: 2, background: "rgba(255, 255, 255, 0.06)" }}
        fullWidth
        required
        multiline
        label={"Enter dashboard description"}
        autoComplete={"off"}
        rows={4}
        type={"text"}
        {...register("description", { required: true })}
        error={!!errors?.description}
        helperText={errors?.description?.messages}
      />
      <TextField
        sx={{ mb: 2, background: "rgba(255, 255, 255, 0.06)" }}
        required
        fullWidth
        label={"Enter dashboard URL"}
        autoComplete={"off"}
        type={"url"}
        {...register("dashboard_url", { required: true })}
        error={!!errors?.dashboard_url}
        helperText={errors?.dashboard_url?.messages}
      />
      <br />
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Button
          variant="contained"
          component="label"
          sx={{
            background: "rgba(255, 255, 255, 0.06)",
            borderRadius: 2,
            color: "white",
            border: "1px solid #808191",
            px: 2,
            "&:hover": {
              background: "none",
            },
          }}
        >
          Upload Logo
          <input
            type="file"
            accept={".jpg, .jpeg, .png, .svg"}
            hidden
            onChange={handleFileInput}
          />
        </Button>
        {fileName !== "" && (
          <Typography
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              padding: "4px 12px 4px 16px",
            }}
          >
            {fileName}
          </Typography>
        )}
      </Stack>
      <br />
      <LoadingButton variant={"contained"} loading={submitting} type="submit">
        Create Dashboard
      </LoadingButton>
    </form>
  );
};

export default NewDashboardForm;
